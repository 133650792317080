import React from 'react';
import useModelSelection from '../../../../../hooks/useModelSelection';
import FNMekaLogo from '../../../../../resources/images/FNMEKA_LOGO_2.png';
import SlideInLeft from '../../../../transitions/SlideInLeft';
import SocialRow from './SocialRow';

function ProfileDescription() {
  const { selectedModel } = useModelSelection();

  let profile;

  switch (selectedModel) {
    case 'FNMeka':
      profile = (
        <div style={styles.upperContainer}>
          <span style={styles.subtitle}>A.I. ROBOT RAPPER</span>
          <img style={styles.brandImage} src={FNMekaLogo} alt="logo" />
          <SocialRow character="FNMeka" />
        </div>
      );
      break;
    case 'FNNxrmal':
      profile = (
        <div style={styles.upperContainer}>
          <span style={styles.subtitle}>999 HORROR OVERLORD</span>
          <span style={styles.brandTitle}>FNNXRMAL</span>
          <SocialRow character="FNNxrmal" />
        </div>
      );
      break;
    default:
      break;
  }
  return (
    <SlideInLeft>
      <div style={styles.leftHalfContainer}>
        {profile}
      </div>
    </SlideInLeft>
  );
}

const styles = {
  leftHalfContainer: {
    margin: '3em 4em',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  upperContainer: {
    padding: '6em 0 0 0',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  subtitle: {
    color: '#FFF',
    alignSelf: 'flex-end',
    fontFamily: 'VCR_OSD_MONO',
    fontSize: '1.3em',
    fontWeight: 'bold',
    zIndex: 5,
  },
  brandTitle: {
    color: '#FFF',
    fontSize: '6em',
    fontWeight: 'bold',
    zIndex: 5,
  },
  brandImage: {
    padding: '0.5em 0',
  },
};

export default ProfileDescription;
