import React, {
  useState, createContext, useEffect, useCallback,
} from 'react';

const SelectedModelContext = createContext();

export const SelectedModelProvider = ({ children }) => {
  const [selectedModel, setSelectedModel] = useState('FNMeka');
  const [loadingStatus, setLoadingStatus] = useState({
    FNMeka: true,
    FNNxrmal: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadingModels = Object.keys(loadingStatus).filter((key) => loadingStatus[key] === true);
    console.log(loadingStatus);
    setLoading(loadingModels.length > 0);
  }, [loadingStatus]);

  const changeSelectedModel = (model) => {
    setSelectedModel(model);
  };

  const changeLoadingStatus = (model, status) => {
    setLoadingStatus({
      ...loadingStatus,
      [model]: status,
    });
  };

  return (
    <SelectedModelContext.Provider value={{
      selectedModel, changeSelectedModel, loadingStatus, changeLoadingStatus, loading, setLoading,
    }}
    >
      {children}
    </SelectedModelContext.Provider>
  );
};

export const SelectedModelConsumer = SelectedModelContext.Consumer;

export default SelectedModelContext;
