import React from 'react';
import { SlideInLeft } from '../../../../transitions';

function ContactOverlay() {
  return (
    <div style={styles.centeredFlex}>
      <SlideInLeft>
        <div style={styles.menuText}>INFO@FACTORYNEW.COM</div>
      </SlideInLeft>
    </div>
  );
}

const styles = {
  centeredFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '3em',
  },
  menuText: {
    color: 'white',
    textAlign: 'center',
    fontSize: '2em',
    cursor: 'pointer',
    fontFamily: 'VCR_OSD_MONO',
  },
};

export default ContactOverlay;
