import { useEffect } from 'react';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';

function SceneBackground({ imgPath }) {
  const { scene } = useThree();

  useEffect(() => {
    if (imgPath) {
      const bgImg = new THREE.TextureLoader().load(imgPath);
      scene.background = bgImg;
    }
  }, [imgPath]);

  return null;
}

export default SceneBackground;
