import React, {
  useState, useMemo,
} from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import * as THREE from 'three';
import FNNxrmal from '../../../resources/3dmodels/models/standing.obj';
import {
  BodyDiffuse, BodyNormal, BodyRoughness, BodyAO,
} from '../../../resources/3dmodels/textures/FNNxrmal/body';
import {
  HeadDiffuse, HeadNormal, HeadRoughness, HeadEmissive, HeadMetalness, HeadAO,
} from '../../../resources/3dmodels/textures/FNNxrmal/head';
import {
  HandDiffuse, HandNormal, HandRoughness, HandMetalness,
} from '../../../resources/3dmodels/textures/FNNxrmal/hand';
import {
  HoodieDiffuse, HoodieNormal, HoodieRoughness, HoodieMetalness,
} from '../../../resources/3dmodels/textures/FNNxrmal/hoodie';
import {
  VestDiffuse, VestMetalness, VestNormal, VestRoughness,
} from '../../../resources/3dmodels/textures/FNNxrmal/vest';
import {
  PantsDiffuse, PantsMetalness, PantsNormal, PantsRoughness,
} from '../../../resources/3dmodels/textures/FNNxrmal/pants';
import {
  ShoesAO, ShoesDiffuse, ShoesNormal, ShoesRoughness,
} from '../../../resources/3dmodels/textures/FNNxrmal/shoes';

export default function FNNxrmalModelPromise({
  position, rotation,
}) {
  const [obj, setObj] = useState();
  const [mixer, setmixer] = useState();

  const tLoader = new TGALoader();

  const loadTexture = (url) => new Promise((resolve) => {
    new TGALoader().load(url, resolve);
  });

  const loadMaterial = async (mapList, paramList) => {
    const params = { ...paramList };
    const promises = Object.keys(mapList).map(
      (key) => loadTexture(mapList[key]).then(
        (texture) => {
          params[key] = texture;
        },
      ),
    );
    await Promise.all(promises);
    return new THREE.MeshStandardMaterial(params);
  };

  const bodyMapList = {
    aoMap: BodyAO,
    map: BodyDiffuse,
    normalMap: BodyNormal,
    roughnessMap: BodyRoughness,
  };

  const bodyParamList = {
    roughness: 1,
    aoMapIntensity: 1,
  };

  const handMapList = {
    map: HandDiffuse,
    normalMap: HandNormal,
    metalnessMap: HandMetalness,
    roughnessMap: HandRoughness,
  };

  const handParamList = {
    metalness: 1,
    roughness: 1,
  };

  const headMapList = {
    map: HeadDiffuse,
    aoMap: HeadAO,
    normalMap: HeadNormal,
    metalnessMap: HeadMetalness,
    roughnessMap: HeadRoughness,
    emissiveMap: HeadEmissive,
  };

  const headParamList = {
    metalness: 1,
    emissive: 'yellow',
  };

  const hoodieMapList = {
    map: HoodieDiffuse,
    normalMap: HoodieNormal,
    metalnessMap: HoodieMetalness,
    roughnessMap: HoodieRoughness,
  };

  const hoodieParamList = {
    roughness: 1,
    metalness: 1,
  };

  const pantsMapList = {
    map: PantsDiffuse,
    normalMap: PantsNormal,
    metalnessMap: PantsMetalness,
    roughnessMap: PantsRoughness,
  };

  const pantsParamList = {
    roughness: 1,
    metalness: 1,
  };

  const shoesMapList = {
    aoMap: ShoesAO,
    map: ShoesDiffuse,
    normalMap: ShoesNormal,
    roughnessMap: ShoesRoughness,
  };

  const shoesParamList = {
    roughness: 1,
    metalness: 1,
  };

  const vestMapList = {
    map: VestDiffuse,
    normalMap: VestNormal,
    metalnessMap: VestMetalness,
    roughnessMap: VestRoughness,
  };

  const vestParamList = {
    roughness: 1,
    metalness: 1,
  };

  const loadOBJ = (url) => new Promise((resolve) => new OBJLoader().load(url, resolve));

  useMemo(async () => {
    const [
      FNNxrmalModel,
      bodyMaterial,
      handMaterial,
      headMaterial,
      hoodieMaterial,
      pantsMaterial,
      shoesMaterial,
      vestMaterial,
    ] = await Promise.all([
      loadOBJ(FNNxrmal),
      loadMaterial(bodyMapList, bodyParamList),
      loadMaterial(handMapList, handParamList),
      loadMaterial(headMapList, headParamList),
      loadMaterial(hoodieMapList, hoodieParamList),
      loadMaterial(pantsMapList, pantsParamList),
      loadMaterial(shoesMapList, shoesParamList),
      loadMaterial(vestMapList, vestParamList),
    ]);
    FNNxrmalModel.children[0].material = headMaterial;
    FNNxrmalModel.children[1].material = hoodieMaterial;
    FNNxrmalModel.children[2].material = shoesMaterial;
    FNNxrmalModel.children[3].material = shoesMaterial;
    FNNxrmalModel.children[4].material = pantsMaterial;
    FNNxrmalModel.children[5].material = headMaterial;
    FNNxrmalModel.children[6].material = headMaterial;
    FNNxrmalModel.children[7].material = headMaterial;
    FNNxrmalModel.children[8].material = handMaterial;
    FNNxrmalModel.children[9].material = bodyMaterial;
    FNNxrmalModel.children[10].material = hoodieMaterial;
    FNNxrmalModel.children[11].material = vestMaterial;
    FNNxrmalModel.children[12].material = vestMaterial;
    FNNxrmalModel.children[13].material = headMaterial;
    FNNxrmalModel.children[14].material = headMaterial;
    setObj(FNNxrmalModel);
  }, []);

  // useEffect(() => {
  //   if (obj && mixer) {
  //     mixer.clipAction(obj.animations[0]).play();
  //   }
  // }, [obj, mixer]);

  // useFrame((state, delta) => {
  //   if (obj && mixer) {
  //     mixer.update(delta);
  //   }
  // });

  return (
    obj
      ? (
        <mesh
          castShadow
          position={position}
          scale={[0.0125, 0.0125, 0.0125]}
          rotation={rotation}
        >
          <primitive object={obj} />
        </mesh>
      ) : null
  );
}
