import React, { useEffect, useState } from 'react';
import Overlay from './Overlay';
import FNLogo from '../../resources/images/FactoryNewLogo.svg';

function LoadingOverlay() {
  const [blink, setBlink] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setBlink(!blink);
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, [blink]);

  return (
    <Overlay>
      <div style={styles.bg}>
        <img style={styles.logo} src={FNLogo} alt="Factory New Logo" />
        <div style={{ ...styles.text, opacity: blink ? 0.7 : 0.7 }}>
          loading...
        </div>
      </div>
    </Overlay>
  );
}

const styles = {
  bg: {
    backgroundColor: 'rgba(0,0,0,1)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    zIndex: 50000,
  },
  logo: {
    width: '100px',
    padding: '1.5em',
  },
  lottie: {
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'VCR_OSD_MONO',
    color: 'white',
    opacity: '0.7',
    width: '120px',
    fontSize: '1.2em',
    textAlign: 'center',
    transition: '0.1s',
  },
};

export default LoadingOverlay;
