/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';

function CharacterSelectItem({
  onClick, imgSrc, imgAlt, selected,
}) {
  return (
    <div role="button" style={selected ? styles.itemContainerSelected : styles.itemContainer} onClick={onClick}>
      <img style={styles.itemImage} src={imgSrc} alt={imgAlt} />
    </div>
  );
}

const styles = {
  itemContainer: {
    padding: '0.5em 2em',
    height: '10em',
    opacity: '0.3',
    cursor: 'pointer',
    transition: '0.6s',
  },
  itemContainerSelected: {
    padding: '0.5em 2em',
    height: '10em',
    cursor: 'pointer',
    transition: '0.6s',
  },
  itemImage: {
    height: '100%',
  },
};

export default CharacterSelectItem;
