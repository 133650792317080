import { faInstagram, faSoundcloud, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { SlideInLeft } from '../../../../transitions';

function SocialRow({ character }) {
  let socialLinks;

  switch (character) {
    case 'FNMeka':
      socialLinks = {
        tiktok: 'https://www.tiktok.com/@fnmeka?lang=en',
        instagram: 'https://www.instagram.com/fnmeka/?hl=en',
        soundcloud: 'https://soundcloud.com/fnmeka',
      };
      break;
    case 'FNNxrmal':
      socialLinks = {
        tiktok: 'https://www.tiktok.com/@fnnxrmal?',
        instagram: 'https://www.instagram.com/fnnxrmal/',
        soundcloud: null,
      };
      break;
    default:
      break;
  }

  const handleExternalLink = (url) => {
    window.open(
      url,
      '_blank',
    );
  };

  const { tiktok, instagram, soundcloud } = socialLinks;

  if (character === 'FNMeka') {
    return (
      <div style={styles.socialRow}>
        <SlideInLeft delayOffset={600}>
          <FontAwesomeIcon
            color="white"
            style={styles.socialIcon}
            icon={faTiktok}
            onClick={() => handleExternalLink(tiktok)}
          />
        </SlideInLeft>
        <SlideInLeft delayOffset={400}>
          <FontAwesomeIcon
            color="white"
            style={styles.socialIcon}
            icon={faInstagram}
            onClick={() => handleExternalLink(instagram)}
          />
        </SlideInLeft>
        <SlideInLeft delayOffset={200}>
          { soundcloud
            ? (
              <FontAwesomeIcon
                color="white"
                style={styles.socialIcon}
                icon={faSoundcloud}
                onClick={() => handleExternalLink(soundcloud)}
              />
            )
            : (
              <FontAwesomeIcon
                color="white"
                style={{ ...styles.socialIcon, opacity: 0.3 }}
                icon={faSoundcloud}
                onClick={() => {}}
              />
            )}
        </SlideInLeft>
      </div>
    );
  } if (character === 'FNNxrmal') {
    return (
      <div style={styles.socialRow2}>
        <SlideInLeft delayOffset={600}>
          <FontAwesomeIcon
            color="white"
            style={styles.socialIcon}
            icon={faTiktok}
            onClick={() => handleExternalLink(tiktok)}
          />
        </SlideInLeft>
        <SlideInLeft delayOffset={400}>
          <FontAwesomeIcon
            color="white"
            style={styles.socialIcon}
            icon={faInstagram}
            onClick={() => handleExternalLink(instagram)}
          />
        </SlideInLeft>
        <SlideInLeft delayOffset={200}>
          { soundcloud
            ? (
              <FontAwesomeIcon
                color="white"
                style={styles.socialIcon}
                icon={faSoundcloud}
                onClick={() => handleExternalLink(soundcloud)}
              />
            )
            : (
              <FontAwesomeIcon
                color="white"
                style={{ ...styles.socialIcon, opacity: 0.3, cursor: 'default' }}
                icon={faSoundcloud}
                onClick={() => {}}
              />
            )}
        </SlideInLeft>
      </div>
    );
  }
}

const styles = {
  socialRow: {
    display: 'flex',
    marginTop: '-32px',
  },
  socialRow2: {
    display: 'flex',
    marginTop: '0px',
  },
  socialIcon: {
    width: '2em',
    height: '2em',
    padding: '0.3em',
    marginLeft: '0.6em',
    cursor: 'pointer',
  },
};

export default SocialRow;
