import React from 'react';
import { SlideInTop } from '../../transitions';
import TopMenuMobile from './TopMenuMobile';

function TopOverlay({ children }) {
  return (
    <div style={styles.fixedTop}>
      <SlideInTop>
        <TopMenuMobile />
      </SlideInTop>
    </div>
  );
}

const styles = {
  fixedTop: {
    position: 'fixed',
    backgroudColor: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    height: '30%',
    width: '100%',
    top: 0,
  },
};

export default TopOverlay;
