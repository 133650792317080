import React from 'react';

export default function Overlay({ children }) {
  return (
    <div style={styles.overlayContainer}>
      {children}
    </div>
  );
}

const styles = {
  overlayContainer: {
    position: 'absolute',
    zIndex: 500,
  },
};
