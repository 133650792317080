import React, { useState, useCallback, useEffect } from 'react';
import { useFrame } from 'react-three-fiber';

const defaultColorDuration = 8;

function MovingSpotLight({
  position = [0, 0, 0],
  color = 'green',
  intensity = 1,
  transitionDuration = 0,
}) {
  const [lightPosition, setlightPosition] = useState(position);
  const [posSpeed, setPosSpeed] = useState([0, 0, 0]);
  const [duration, setDuration] = useState(transitionDuration);
  const [lightColor, setLightColor] = useState(color);
  const [colorDuration, setColorDuration] = useState(defaultColorDuration);
  const [lightIntensity, setLightIntensity] = useState(intensity);
  const [intensitySpeed, setIntensitySpeed] = useState();

  const calculatePositionSpeed = useCallback(
    () => {
      const [currentX, currentY, currentZ] = lightPosition;
      const [nextX, nextY, nextZ] = position;
      const x = (nextX - currentX) / (transitionDuration);
      const y = (nextY - currentY) / (transitionDuration);
      const z = (nextZ - currentZ) / (transitionDuration);
      setPosSpeed([x, y, z]);
    },
    [position, transitionDuration],
  );

  const calculateLightIntensity = useCallback(
    () => {
      setIntensitySpeed(intensity / (defaultColorDuration));
    },
    [color, intensity],
  );

  useEffect(() => {
    setDuration(transitionDuration);
    calculatePositionSpeed();
  }, [position, intensity, transitionDuration]);

  useEffect(() => {
    setColorDuration(defaultColorDuration);
    calculateLightIntensity();
  }, [color, intensity]);

  useFrame(() => {
    if (duration > 0) {
      setDuration(duration - 1);
      const [x, y, z] = lightPosition;
      const [speedX, speedY, speedZ] = posSpeed;
      setlightPosition([x + speedX, y + speedY, z + speedZ]);
    }
    if (colorDuration > 0) {
      setColorDuration(colorDuration - 1);
      setLightIntensity(lightIntensity - intensitySpeed);
    } else if (colorDuration === 0) {
      setColorDuration(colorDuration - 1);
      setLightColor(color);
    } else if (colorDuration < 0) {
      if (lightIntensity < intensity) {
        setColorDuration(colorDuration - 1);
        setLightIntensity(lightIntensity + intensitySpeed);
      }
    }
  });

  return (
    <spotLight position={lightPosition} color={lightColor} castShadow intensity={lightIntensity} />
  );
}

export default MovingSpotLight;
