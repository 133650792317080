/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback } from 'react';
import useModelSelection from '../../../../../hooks/useModelSelection';
import FNMekaSelect from '../../../../../resources/images/character_select_profiles/FNMekaProfileReduced.jpeg';
import FNNormalSelect from '../../../../../resources/images/character_select_profiles/FNNxrmalProfileReduced.jpeg';
import { SlideInLeft } from '../../../../transitions';
import CharacterSelectItem from './CharacterSelectItem';

function CharacterSelect() {
  const { selectedModel, changeSelectedModel } = useModelSelection();

  const handleClick = useCallback((model) => {
    changeSelectedModel(model);
  }, [selectedModel]);

  return (
    <SlideInLeft>
      <div style={styles.centeredFlex}>
        <CharacterSelectItem
          imgSrc={FNMekaSelect}
          imgAlt="FNMekaSelect"
          selected={selectedModel === 'FNMeka'}
          onClick={() => handleClick('FNMeka')}
        />
        <CharacterSelectItem
          imgSrc={FNNormalSelect}
          imgAlt="FNNormalSelect"
          selected={selectedModel === 'FNNxrmal'}
          onClick={() => handleClick('FNNxrmal')}
        />
      </div>
    </SlideInLeft>
  );
}

const styles = {
  centeredFlex: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    padding: '0.5em 2em',
    height: '10em',
    opacity: '0.3',
    cursor: 'pointer',
  },
  itemContainer2: {
    padding: '0.5em 2em',
    height: '10em',
    cursor: 'pointer',
  },
  itemImage: {
    height: '100%',
  },
};

export default CharacterSelect;
