/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { SlideInLeft } from '../../../../transitions';

function NewsItem({
  title = 'TITLE', subtitle = 'Subtitle', caption = '', imageSrc, onClick, index,
}) {
  const [hovered, setHovered] = useState(false);
  return (
    <SlideInLeft delayOffset={(index + 1) * 200}>
      <div
        style={hovered ? styles.containerHovered : styles.container}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
      >
        {
          imageSrc
            ? <img style={styles.image} src={imageSrc} alt="News Caption" />
            : <FontAwesomeIcon color="white" style={styles.image} icon={faNewspaper} />
        }
        <div style={styles.textContainer}>
          <div style={styles.title}>{title}</div>
          <div style={styles.subtitle}>{subtitle}</div>
          <div style={styles.caption}>{caption}</div>
        </div>
      </div>
    </SlideInLeft>
  );
}

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.2em 1em 1.2em 0',
    margin: '0.2em 0',
    cursor: 'pointer',
    borderLeft: '2px solid transparent',
    transition: '0.5s',
  },
  containerHovered: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.2em 1em 1.2em 0',
    margin: '0.2em 0',
    cursor: 'pointer',
    borderLeft: '2px solid white',
    transition: '0.5s',
  },
  image: {
    display: 'flex',
    width: '5em',
    height: '5em',
    padding: '0 2.5em 0 2.5em',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: 'white',
    fontFamily: 'VCR_OSD_MONO',
    fontSize: '1.3em',
    fontWeight: '600',
  },
  subtitle: {
    color: 'white',
    fontFamily: 'VCR_OSD_MONO',
    fontWeight: '600',
    paddingBottom: '0.5em',
    paddingTop: '0.2em',
  },
  caption: {
    color: 'white',
    fontFamily: 'VCR_OSD_MONO',
    opacity: '0.6',
  },
};

export default NewsItem;
