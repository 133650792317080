import React from 'react';
import CharacterSelect from './contents/character-select/CharacterSelect';
import ContactOverlay from './contents/contact/ContactOverlay';
import NewsOverlay from './contents/news/NewsOverlay';
import ProfileDescription from './contents/profile/ProfileDescription';

function LeftOverlay({ phase }) {
  let content;
  switch (phase) {
    case 'home':
      content = <ProfileDescription />;
      break;
    case 'news':
      content = <NewsOverlay />;
      break;
    case 'character':
      content = <CharacterSelect />;
      break;
    case 'contact':
      content = <ContactOverlay />;
      break;
    default:
      break;
  }

  return (
    <div style={styles.outerPadding}>
      {content}
    </div>
  );
}

const styles = {
  outerPadding: {
    position: 'fixed',
    width: '50%',
    height: '100%',
    display: 'flex',
    left: 0,
  },
};

export default LeftOverlay;
