import React, {
  useState, useMemo,
} from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import * as THREE from 'three';
import FNmeka from '../../../resources/3dmodels/models/FNMEKA1.obj';
import {
  BodyDiffuse, BodyNormal, BodyRoughness, BodyEmissive,
} from '../../../resources/3dmodels/textures/FNMeka/body';
import {
  HairDiffuse, HairNormal, HairRoughness,
} from '../../../resources/3dmodels/textures/FNMeka/hair';
import {
  ChainDiffuse, ChainNormal, ChainRoughness, ChainEmissive, ChainReflection,
} from '../../../resources/3dmodels/textures/FNMeka/chain';
import {
  HandDiffuse, HandNormal, HandRoughness, HandReflection,
} from '../../../resources/3dmodels/textures/FNMeka/hand';
import {
  JacketDiffuse, JacketNormal, JacketRoughness,
} from '../../../resources/3dmodels/textures/FNMeka/jacket';
import {
  MaskDiffuse, MaskNormal,
} from '../../../resources/3dmodels/textures/FNMeka/mask';
import {
  PuffyPantsDiffuse, PuffyPantsNormal,
} from '../../../resources/3dmodels/textures/FNMeka/pants';
import {
  PuffyShoeDiffuse, PuffyShoeNormal, PuffyShoeEmissive,
} from '../../../resources/3dmodels/textures/FNMeka/shoes';

export default function FNMekaModelPromise({
  position, rotation, setLoading,
}) {
  const [obj, setObj] = useState();
  const [mixer, setmixer] = useState();

  const loadTexture = (url) => new Promise((resolve) => {
    new THREE.TextureLoader().load(url, resolve);
  });

  const loadMaterial = async (mapList, paramList) => {
    const params = { ...paramList };
    const promises = Object.keys(mapList).map(
      (key) => loadTexture(mapList[key]).then(
        (texture) => {
          params[key] = texture;
        },
      ),
    );
    await Promise.all(promises);
    return new THREE.MeshStandardMaterial(params);
  };

  const bodyMapList = {
    map: BodyDiffuse,
    normalMap: BodyNormal,
    roughnessMap: BodyRoughness,
    emissiveMap: BodyEmissive,
  };

  const bodyParamList = {
    roughness: 1,
    emissive: 'green',
  };

  const chainMapList = {
    map: ChainDiffuse,
    normalMap: ChainNormal,
    emissiveMap: ChainEmissive,
    metalnessMap: ChainReflection,
    roughnessMap: ChainRoughness,
  };

  const chainParamList = {
    emissive: 'green',
    metalness: 1,
  };

  const hairMapList = {
    map: HairDiffuse,
    normalMap: HairNormal,
    roughnessMap: HairRoughness,
  };

  const handMapList = {
    map: HandDiffuse,
    normalMap: HandNormal,
    metalnessMap: HandReflection,
    roughnessMap: HandRoughness,
  };

  const handParamList = {
    roughness: 1,
    metalness: 1,
  };

  const jacketMapList = {
    map: JacketDiffuse,
    normalMap: JacketNormal,
    roughnessMap: JacketRoughness,
  };

  const maskMapList = {
    map: MaskDiffuse,
    normalMap: MaskNormal,
  };

  const shoesMapList = {
    map: PuffyShoeDiffuse,
    normalMap: PuffyShoeNormal,
    emissiveMap: PuffyShoeEmissive,
  };

  const shoesParamList = {
    emissive: 'green',
  };

  const pantsMapList = {
    map: PuffyPantsDiffuse,
    normalMap: PuffyPantsNormal,
  };

  const loadOBJ = (url) => new Promise((resolve) => new OBJLoader().load(url, resolve));

  useMemo(async () => {
    const [
      FNMekaModel,
      bodyMaterial,
      chainMaterial,
      hairMaterial,
      handMaterial,
      jacketMaterial,
      maskMaterial,
      pantsMaterial,
      shoesMaterial,
    ] = await Promise.all([
      loadOBJ(FNmeka),
      loadMaterial(bodyMapList, bodyParamList),
      loadMaterial(chainMapList, chainParamList),
      loadMaterial(hairMapList),
      loadMaterial(handMapList, handParamList),
      loadMaterial(jacketMapList),
      loadMaterial(maskMapList),
      loadMaterial(pantsMapList),
      loadMaterial(shoesMapList, shoesParamList),

    ]);
    FNMekaModel.children[0].material[0] = bodyMaterial;
    FNMekaModel.children[0].material[1] = handMaterial;
    FNMekaModel.children[0].material[2] = jacketMaterial;
    FNMekaModel.children[0].material[3] = pantsMaterial;
    FNMekaModel.children[0].material[4] = shoesMaterial;
    FNMekaModel.children[0].material[5] = hairMaterial;
    FNMekaModel.children[0].material[6] = bodyMaterial;
    // fbx.children[0].material[7] = hairMaterial;
    FNMekaModel.children[0].material[8] = chainMaterial;
    FNMekaModel.children[0].material[9] = maskMaterial;
    FNMekaModel.children[0].material[10] = bodyMaterial;
    setObj(FNMekaModel);
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   if (obj && mixer) {
  //     mixer.clipAction(obj.animations[0]).play();
  //   }
  // }, [obj, mixer]);

  // useFrame((state, delta) => {
  //   if (obj && mixer) {
  //     mixer.update(delta);
  //   }
  // });

  return (
    obj
      ? (
        <mesh
          castShadow
          position={position}
          scale={[0.015, 0.015, 0.015]}
          rotation={rotation}
        >
          <primitive object={obj} />
        </mesh>
      ) : null
  );
}
