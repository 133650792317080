import { animated, config, useTransition } from 'react-spring';
import React from 'react';
import BottomOverlay from '../menu/bottom-overlay/BottomOverlay';
import LeftOverlay from '../menu/left-overlay/LeftOverlay';
import SelectMenu from '../menu/SelectMenu';
import TopOverlay from '../menu/top-overlay/TopOverlay';
import LoadingOverlay from './LoadingOverlay';
import Overlay from './Overlay';

function OverlayFlow({
  isMobile, loading, menuPhase, menuIndex,
}) {
  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: {
      opacity: 0,
      delay: 300,
    },
    config: {
      tension: 280,
      friction: 100,
    },
  });

  if (isMobile) {
    return (
      <Overlay>
        <TopOverlay phase={menuPhase} />
        <BottomOverlay menuIndex={menuIndex} />
      </Overlay>
    );
  }

  return (
    <Overlay>
      {transitions((springStyle, item) => (item
        ? (
          <animated.div style={springStyle}>
            <LoadingOverlay />
          </animated.div>
        )
        : (
          <animated.div style={springStyle}>
            <LeftOverlay phase={menuPhase} />
            <SelectMenu menuIndex={menuIndex} />
          </animated.div>
        )))}
    </Overlay>
  );
}

export default OverlayFlow;
