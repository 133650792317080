import React from 'react';
import { useSpring, animated } from 'react-spring';

const SlideInTop = ({ children, delay = 600, delayOffset = 0 }) => {
  const springProps = useSpring(
    {
      to: { opacity: 1, y: 0 },
      from: { opacity: 0, y: -100 },
      delay: delay + delayOffset,
    },
  );
  return (
    <animated.div style={{ ...styles.container, ...springProps }}>
      {children}
    </animated.div>
  );
};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
  },
};

export default SlideInTop;
