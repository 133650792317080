/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import useModelSelection from '../../../../../hooks/useModelSelection';
import FNMekaLogo from '../../../../../resources/images/FNMEKA_LOGO_2.png';
import { SlideInBottom } from '../../../../transitions';
import SocialRowMobile from './SocialRowMobile';

function ProfileDescriptionMobile() {
  const { selectedModel } = useModelSelection();

  let profile;

  switch (selectedModel) {
    case 'FNMeka':
      profile = (
        <SlideInBottom>
          <div style={styles.upperContainer}>
            <span style={styles.subtitle}>A.I. ROBOT RAPPER</span>
            <img style={styles.brandImage} src={FNMekaLogo} alt="logo" />
            <SocialRowMobile character="FNMeka" />
          </div>
        </SlideInBottom>
      );
      break;
    case 'FNNxrmal':
      profile = (
        <SlideInBottom>
          <div style={styles.upperContainer}>
            <span style={styles.subtitle}>999 HORROR OVERLORD</span>
            <span style={styles.brandTitle}>FNNXRMAL</span>
            <SocialRowMobile character="FNNxrmal" />
          </div>
        </SlideInBottom>
      );
      break;
    default:
      break;
  }
  return (
    <div style={styles.leftHalfContainer}>
      {profile}
    </div>
  );
}

const styles = {
  leftHalfContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  upperContainer: {
    padding: '2.5em 1em',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    color: '#FFF',
    alignSelf: 'center',
    fontFamily: 'VCR_OSD_MONO',
    fontSize: '1.3em',
    fontWeight: 'bold',
    zIndex: 5,
  },
  brandTitle: {
    color: '#FFF',
    fontSize: '3em',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '0.2em 0 0.6em 0',
    zIndex: 5,
  },
  brandImage: {
    justifyContent: 'center',
    alignSelf: 'center',
    padding: '0.5em',
    width: '100%',
  },
};

export default ProfileDescriptionMobile;
