import React from 'react';
import { useSpring, animated } from 'react-spring';
import MenuBranding from './menu-item/MenuBranding';
import MenuItem from './menu-item/MenuItem';
import FNLogo from '../../resources/images/FactoryNewLogo.svg';

function SelectMenu({ menuIndex }) {
  const springProps = useSpring(
    {
      to: { opacity: 1, x: 0 },
      from: { opacity: 0, x: 100 },
      delay: 400,
    },
  );

  const menuList = menuIndex.map((item, index) => {
    const { title, onPress } = item;
    return (
      <MenuItem key={index} onPress={onPress} title={title} />
    );
  });

  return (
    <>
      <animated.div style={{ ...styles.rightHalfContainer, ...springProps }}>
        <MenuBranding imgPath={FNLogo} />
        {menuList}
      </animated.div>
    </>
  );
}

const styles = {
  rightHalfContainer: {
    position: 'fixed',
    display: 'flex',
    marginTop: '-2em',
    right: 0,
    width: '25%',
    height: '100%',
    backgroundColor: '#11111100',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  imageTexture: {
    position: 'absolute',
    top: 0,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    opacity: '0.1',
  },
  brandingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  menuItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
};

export default SelectMenu;
