import { useEffect, useState } from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import { ExpandedBezierFormula } from '../../../helpers/parametric-formulas';
import useMousePosition from '../../../hooks/useMousePosition';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function Dolly({
  nextPosition, nextRotation, transitionDuration,
}) {
  nextPosition = nextPosition ?? { x: 0, y: 0, z: 5 };
  nextRotation = nextRotation ?? { x: 0, y: 0, z: 0 };

  const { camera } = useThree();
  const { mouseX, mouseY } = useMousePosition();
  const { width, height } = useWindowDimensions();
  const [startPosition, setStartPosition] = useState();
  const [startRotation, setStartRotation] = useState();
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    setCurrentTime(0);
    setStartPosition({ x: camera.position.x, y: camera.position.y, z: camera.position.z });
    setStartRotation({ x: camera.rotation.x, y: camera.rotation.y, z: camera.rotation.z });
  }, [nextPosition, nextRotation, transitionDuration]);

  const offset = {
    x: (-(mouseX - (width / 2)) / 75000),
    y: (-(mouseY - (height / 2)) / 75000),
  };

  const calculateEasePosition = () => {
    const x = ExpandedBezierFormula(
      currentTime,
      startPosition.x,
      nextPosition.x,
      transitionDuration,
    );
    const y = ExpandedBezierFormula(
      currentTime,
      startPosition.y,
      nextPosition.y,
      transitionDuration,
    );
    const z = ExpandedBezierFormula(
      currentTime,
      startPosition.z,
      nextPosition.z,
      transitionDuration,
    );
    return { x, y, z };
  };

  const calculateEaseRotation = () => {
    const x = ExpandedBezierFormula(
      currentTime,
      startRotation.x,
      nextRotation.x,
      transitionDuration,
    );
    const y = ExpandedBezierFormula(
      currentTime,
      startRotation.y,
      nextRotation.y,
      transitionDuration,
    );
    const z = ExpandedBezierFormula(
      currentTime,
      startRotation.z,
      nextRotation.z,
      transitionDuration,
    );
    return { x, y, z };
  };

  useFrame(() => {
    if (currentTime <= transitionDuration) {
      camera.position.x = calculateEasePosition().x;
      camera.position.y = calculateEasePosition().y;
      camera.position.z = calculateEasePosition().z;

      camera.rotation.x = calculateEaseRotation().x + offset.y;
      camera.rotation.y = calculateEaseRotation().y + offset.x;
      camera.rotation.z = calculateEaseRotation().z;

      setCurrentTime(currentTime + 1);
    } else {
      camera.rotation.x = nextRotation.x + offset.y;
      camera.rotation.y = nextRotation.y + offset.x;
    }
  });

  return null;
}
