import React, { useEffect, useState } from 'react';
import { useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import texture from '../../../resources/images/pngegg.png';

function ParticleEngine({
  particleCount = 2000, xBounds = [-5, 5], yBounds = [-1, 2], zBounds = [-2, 2],
}) {
  const [particleGeometry, setParticleGeometry] = useState();
  const [particleMaterial, setParticleMaterial] = useState();
  const [particlePosition, setParticlePosition] = useState([0, -1.25, -2.2]);
  const [particleRotation, setParticleRotation] = useState([0, 0, 0]);

  useEffect(() => {
    const geometry = new THREE.BufferGeometry();
    const material = new THREE.PointsMaterial({
      color: 0x11A4FF,
      size: 0.02,
      map: new THREE.TextureLoader().load(texture),
      transparent: true,
    });

    const positions = [];
    const colors = [];
    const sizes = [];

    const color = new THREE.Color();
    // now create the individual particles
    for (let p = 0; p < particleCount; p += 1) {
      // create a particle with random
      // position values, -250 -> 250
      positions.push((Math.random() * (xBounds[1] - xBounds[0]) - xBounds[1]));
      positions.push((Math.random() * (yBounds[1] - yBounds[0]) - yBounds[1]));
      positions.push((Math.random() * (zBounds[1] - zBounds[0]) - zBounds[1]));

      color.setHSL(p / particleCount, 1.0, 0.5);
      colors.push(color.r, color.g, color.b);

      sizes.push(2);

      geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
      geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
      geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

      setParticleGeometry(geometry);
      setParticleMaterial(material);
    }
  }, []);

  useFrame(() => {
    setParticleRotation([particleRotation[0] - 0.002, 0, 0]);
  });

  return (
    <points
      geometry={particleGeometry}
      material={particleMaterial}
      position={particlePosition}
      rotation={particleRotation}
    />
  );
}

export default ParticleEngine;
