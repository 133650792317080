import React, { useState } from 'react';

function MenuItem({ title, onPress }) {
  const [hovered, setHovered] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      style={hovered ? styles.menuItemHovered : styles.menuItem}
      onClick={onPress}
      onKeyPress={onPress}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <h1 style={styles.menuText}>
        {title}
      </h1>
    </div>
  );
}

const styles = {
  menuItemHovered: {
    display: 'flex',
    cursor: 'pointer',
    zIndex: 5,
    padding: '0.5em 0',
    textShadow: '0px 0px 10px white',
    transition: '0.4s',
  },
  menuItem: {
    display: 'flex',
    cursor: 'pointer',
    zIndex: 5,
    padding: '0.5em 0',
    opacity: '0.6',
    // borderBottom: '2px solid transparent',
  },
  menuText: {
    color: 'white',
    fontSize: '1.5em',
    cursor: 'pointer',
    fontFamily: 'VCR_OSD_MONO',
  },
};

export default MenuItem;
