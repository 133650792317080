import React, { useRef } from 'react';

function Plane({
  position, rotation, size, basic,
}) {
  const mesh = useRef();

  return (
    <mesh
      ref={mesh}
      position={position}
      rotation={rotation}
      castShadow
    >
      <planeBufferGeometry args={size} />
      {basic
        ? <meshBasicMaterial color="#222" />
        : <meshPhongMaterial color="#222" /> }
    </mesh>
  );
}

export default Plane;
