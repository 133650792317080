import './App.css';
import {
  useCallback, useState, useEffect, Suspense,
} from 'react';
import { Canvas } from 'react-three-fiber';

import ThreeJSGroup from './components/threejs/threejs-group/ThreeJSGroup';
import Dolly from './components/threejs/camera/Dolly';
import SceneBackground from './components/threejs/background/SceneBackground';
import ParticleEngine from './components/threejs/particles/ParticleEngine';
import Plane from './components/threejs/mesh/Plane';
import MovingSpotlight from './components/threejs/lighting/MovingSpotLight';

import useModelSelection from './hooks/useModelSelection';
import useWindowDimensions from './hooks/useWindowDimensions';
import OverlayFlow from './components/layout/OverlayFlow';
import FNMekaModelPromise from './components/threejs/FNMekaModel/FNMekaModelPromise';
import FNNxrmalModelPromise from './components/threejs/FNNxrmalModel/FNNxrmalModelPromise';

function App() {
  const [cameraPos, setCameraPos] = useState({ x: 0, y: 0.35, z: 1.5 });
  const [cameraRotation, setCameraRotation] = useState({ x: 0.1, y: 0, z: 0 });
  const [spotLightColor, setSpotLightColor] = useState('green');
  const [menuPhase, setMenuPhase] = useState('home');
  const { selectedModel, loading, setLoading } = useModelSelection();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (selectedModel === 'FNMeka') {
      setSpotLightColor('green');
    } else {
      setSpotLightColor('red');
    }
  }, [selectedModel]);

  const moveCamera = useCallback(
    (newX, newY, newZ) => {
      setCameraPos({ x: newX, y: newY, z: newZ });
    },
    [],
  );

  const rotateCamera = useCallback(
    (newX, newY, newZ) => {
      setCameraRotation({ x: newX, y: newY, z: newZ });
    },
    [],
  );

  const menuIndex = [
    {
      title: 'HOME',
      onPress: () => {
        moveCamera(0, 0.35, 1.5);
        rotateCamera(0.1, 0, 0);
        setMenuPhase('home');
      },
    },
    {
      title: 'NEWS',
      onPress: () => {
        moveCamera(1, 0.8, 0.7);
        rotateCamera(0, Math.PI / 2, 0);
        setMenuPhase('news');
      },
    },
    {
      title: 'CHARACTERS',
      onPress: () => {
        moveCamera(0, 0, 3);
        rotateCamera(0, 0, 0);
        setMenuPhase('character');
      },
    },
    {
      title: 'MERCH',
      onPress: () => {
        window.open('https://factorynew.gg/', '_blank');
      },
    },
    {
      title: 'CONTACT',
      onPress: () => {
        moveCamera(0.5, -0.2, 1);
        rotateCamera(-Math.PI / 6, Math.PI / 4, 0);
        setMenuPhase('contact');
      },
    },
  ];

  return (
    <div>
      <OverlayFlow
        loading={loading}
        isMobile={isMobile}
        menuPhase={menuPhase}
        menuIndex={menuIndex}
      />
      <div style={styles.background}>
        <Suspense fallback={<div style={{ backgroundColor: 'yellow' }}>loading</div>}>
          <Canvas camera={{ fov: 75, position: [0, 0.35, 1.5] }}>
            <ambientLight intensity={0.1} />
            <pointLight position={[0, 10, -5]} intensity={1} color="#CCC" />
            <ThreeJSGroup offset={[0, -4, 3]}>
              <pointLight
                position={[-2, 15, -8]}
                color="#fdfbd3"
                transitionDuration={120}
                intensity={1}
              />
              <MovingSpotlight
                position={[0, 0, 0]}
                color={spotLightColor}
                transitionDuration={120}
                intensity={0.5}
              />
              <MovingSpotlight
                position={[-5, 0, 0]}
                color={spotLightColor}
                transitionDuration={120}
                intensity={0.5}
              />
              <MovingSpotlight
                position={[5, 0, 0]}
                color={spotLightColor}
                transitionDuration={120}
                intensity={0.5}
              />
            </ThreeJSGroup>
            <SceneBackground />
            <ParticleEngine />
            <ThreeJSGroup offset={[0, 0, 0]}>
              {/* {selectedModel === 'FNNxrmal'
                ? (
                  <FNNxrmalModelPromise
                    setLoading={setLoading}
                    position={[0, -1, 0]}
                    rotation={[Math.PI / 2, 0, 0]}
                  />
                )
                : (
                  <FNMekaModelPromise
                    setLoading={setLoading}
                    position={[0, -1.25, 0]}
                    rotation={[0, 0, 0]}
                  />
                )} */}
              <FNNxrmalModelPromise
                setLoading={setLoading}
                position={selectedModel === 'FNNxrmal' ? [0, -1, 0] : [0, -1, -10]}
                rotation={[0, 0, 0]}
              />
              <FNMekaModelPromise
                setLoading={setLoading}
                position={selectedModel === 'FNMeka' ? [0, -1.25, 0] : [0, -1.25, -10]}
                rotation={[0, 0, 0]}
              />
              <Plane position={[0, 0, -1]} size={[2000, 2000]} />
            </ThreeJSGroup>
            <Dolly
              nextPosition={cameraPos}
              nextRotation={cameraRotation}
              transitionDuration={50}
            />
          </Canvas>
        </Suspense>
      </div>
    </div>
  );
}

const styles = {
  background: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'blue',
    mixBlendMode: 'darken',
  },
};

export default App;
