import React from 'react';
import ProfileDescriptionMobile from '../left-overlay/contents/profile/ProfileDescriptionMobile';

function BottomOverlay({ children }) {
  return (
    <div style={styles.fixedBottom}>
      <ProfileDescriptionMobile />
    </div>
  );
}

const styles = {
  fixedBottom: {
    position: 'fixed',
    justifyContent: 'center',
    height: '30%',
    width: '100%',
    display: 'flex',
    bottom: 0,
  },
};

export default BottomOverlay;
