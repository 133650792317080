import React from 'react';

export default function ThreeJSGroup({ children, offset }) {
  const newChildren = React.Children.map(children, (child) => {
    const { position } = child.props;
    let newProps;
    if (position && offset) {
      newProps = {
        position: [position[0] + offset[0], position[1] + offset[1], position[2] + offset[2]],
      };
    }
    return React.cloneElement(child, { ...child.props, ...newProps });
  });

  return (
    <>
      {newChildren}
    </>
  );
}
