import React, { useCallback } from 'react';
import { SlideInLeft } from '../../../../transitions';
import NewsItem from './NewsItem';

const NEWSINDEX = [{
  title: 'Meet FN Meka, the World’s First AI Robot Rapper Who Sells NFTs',
  subtitle: 'The Daily Beast (Apr. 03, 2021)',
  caption: 'FN Meka has more than 9 million TikTok followers, released three songs, and recently sold a Lamborghini porta-potty NFT for $6,500.',
  url: 'https://www.thedailybeast.com/meet-fn-meka-the-worlds-first-ai-robot-rapper-who-sells-nfts',
}, {
  title: 'THIS ROBOT RAPPER HAS 9M FOLLOWERS ON TIKTOK. THE COMPANY THAT CREATED HIM THINKS TRADITIONAL A&R IS ‘INEFFICIENT AND UNRELIABLE’',
  subtitle: 'https://www.musicbusinessworldwide.com (Apr. 01, 2021)',
  caption: 'Is being human still a prerequisite for being an ‘artist’? We don’t mean the ability to display humanity. We quite literally mean: does an artist really need to be a human to be considered an actual artist, who can perform and record music, generate streams, market singles and albums, and build a fanbase?',
  url: 'https://www.musicbusinessworldwide.com/this-robot-rapper-has-9-million-followers-on-tiktok-his-creator-thinks-traditional-ar-is-inefficient-and-unreliable/',
}, {
  title: 'The world’s first AI rapper FN Meka has released a new single',
  subtitle: 'https://routenote.com (Mar. 15, 2021)',
  caption: 'Listen to “Speed Demon”, a trap track created by a robot rapper.',
  url: 'https://routenote.com/blog/the-worlds-first-ai-rapper-fn-meka-has-released-a-new-single/',
}];

function NewsOverlay() {
  const redirectOnClick = useCallback(
    (url) => {
      window.open(url);
    },
    [],
  );

  const newsItemList = NEWSINDEX.map((item, i) => {
    const {
      title, subtitle, caption, url,
    } = item;
    return (
      <NewsItem key={`news-item-${i}`} title={title} subtitle={subtitle} caption={caption} onClick={() => redirectOnClick(url)} index={i} />
    );
  });

  return (
    <div style={styles.leftHalfContainer}>
      <SlideInLeft>
        <span style={styles.subtitle}>FN NEWS</span>
      </SlideInLeft>
      {newsItemList}
    </div>
  );
}

const styles = {
  leftHalfContainer: {
    padding: '6em 0 0 0',
    margin: '3em 4em',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  subtitle: {
    color: '#FFF',
    alignSelf: 'flex-start',
    fontFamily: 'VCR_OSD_MONO',
    fontSize: '3em',
    padding: '0 0 1em 0',
    fontWeight: 'bold',
    zIndex: 5,
  },
};

export default NewsOverlay;
