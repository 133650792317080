import React, { useState } from 'react';
import { ReactComponent as Branding } from '../../../resources/images/FactoryNewLogo.svg';

function MenuBranding() {
  const [hovered, setHovered] = useState(false);

  return (
    <div style={styles.brandingContainer}>
      <Branding
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        height="200px"
        opacity={hovered ? '100%' : '30%'}
        style={{ transition: '0.3s' }}
      />
    </div>
  );
}

const styles = {
  brandingContainer: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em 1em 0 1em',
    height: '50%',
    width: '100%',
    zIndex: 10,
  },
  brandImage: {
    height: '200px',
    color: 'black',
  },
};

export default MenuBranding;
