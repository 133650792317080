import React from 'react';
import { faGem } from '@fortawesome/free-regular-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModelSelection from '../../../hooks/useModelSelection';

function TopMenuMobile() {
  const { selectedModel, changeSelectedModel } = useModelSelection();

  const handleMerchClick = () => {
    window.open('https://factorynew.gg/', '_blank');
  };

  const handleCharacterChange = () => {
    switch (selectedModel) {
      case 'FNMeka':
        changeSelectedModel('FNNxrmal');
        break;
      case 'FNNxrmal':
        changeSelectedModel('FNMeka');
        break;
      default:
        break;
    }
  };

  return (
    <div style={styles.rowContainer}>
      <div style={styles.leftAlign}>
        <FontAwesomeIcon
          style={styles.leftIcon}
          color="white"
          icon={faGem}
          onClick={handleMerchClick}
        />
      </div>
      <div style={styles.rightAlign}>
        <FontAwesomeIcon
          style={styles.rightIcon}
          color="white"
          icon={faUserFriends}
          onClick={handleCharacterChange}
        />
      </div>
    </div>
  );
}

const styles = {
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftIcon: {
    color: 'white',
    textAlign: 'left',
    width: '1.5em',
    height: '1.5em',
    padding: '1em',
  },
  rightIcon: {
    color: 'white',
    textAlign: 'right',
    width: '1.5em',
    height: '1.5em',
    padding: '1em',
  },
};

export default TopMenuMobile;
